import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Modal from "../../../components/Modals";
import Input from "../../../components/Input/Input";
import requests from "../../../services/requests";
import { FiChevronLeft } from "react-icons/fi";
import { DefaultEditor } from "react-simple-wysiwyg";

// import draftToHtml from 'draftjs-to-html';
// import { EditorState, convertToRaw } from 'draft-js';

const yup = require("yup");

const ModalNew = ({ actionModal, openModal, handleSuccess }) => {
    const [isVideo, setIsVideo] = useState(false);
    const [isFoto, setIsFoto] = useState(false);
    // const [editorState, setEditorState] = useState(EditorState.createEmpty());

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-8/12 min-h-80%"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>Publicar</h5>
                </div>
            }>
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        title: "",
                        content: "",
                        videoUrl: "",
                    }}
                    validationSchema={yup.object().shape({
                        title: yup.string(),
                        content: yup.string(),
                        videoUrl: yup.string(),
                    })}
                    onSubmit={(values, { setFieldError, setSubmitting }) => {
                        setSubmitting(true);

                        requests
                            .addFaq(values)
                            .then(response => {
                                setSubmitting(false);
                                handleSuccess();
                                toast.success("Cadastrado com sucesso!");
                            })
                            .catch(error => {
                                setSubmitting(false);
                            });
                    }}>
                    {({ handleChange, handleBlur, handleSubmit, isSubmitting, values, setFieldValue }) => (
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                            }}>
                            <div className="mx-40">
                                {isVideo && (
                                    <>
                                        <label htmlFor="videoUrl" className="text-roxo_oficial font-bold text-lg flex">
                                            <button
                                                onClick={() => {
                                                    setIsVideo(false);
                                                }}>
                                                <FiChevronLeft />
                                            </button>
                                            <span className="ml-2">URL do youtube embbed</span>
                                        </label>
                                        <Input
                                            name="videoUrl"
                                            id="videoUrl"
                                            placeholder="https://www.youtube.com/embed/xxxxxxxxxxx"
                                            onChange={handleChange}
                                            value={values.videoUrl}
                                        />
                                        <ErrorMessage
                                            component="label"
                                            name="videoUrl"
                                            className="text-red font-light w-full"
                                        />
                                    </>
                                )}

                                {isFoto && (
                                    <div className="w-full">
                                        <label htmlFor="image" className="text-roxo_oficial font-bold text-lg flex">
                                            <button
                                                onClick={() => {
                                                    setIsFoto(false);
                                                }}>
                                                <FiChevronLeft />
                                            </button>
                                            <span className="ml-2">Anexar imagem</span>
                                        </label>
                                        <input
                                            className="block mt-2"
                                            type="file"
                                            name="image"
                                            id="image"
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage
                                            component="label"
                                            name="image"
                                            className="text-red font-light w-full"
                                        />
                                    </div>
                                )}

                                {!isFoto && !isVideo && (
                                    <div className="w-full">
                                        <button
                                            className="bg-roxo_oficial text-white p-2 rounded-lg mr-4"
                                            onClick={() => {
                                                setIsFoto(true);
                                            }}>
                                            Anexar foto
                                        </button>
                                        <button
                                            className="bg-roxo_oficial text-white p-2 rounded-lg"
                                            onClick={() => {
                                                setIsVideo(true);
                                            }}>
                                            Anexar link de vídeo
                                        </button>
                                    </div>
                                )}

                                <label htmlFor="title" className="text-roxo_oficial font-bold text-lg mt-4">
                                    Título do artigo
                                </label>
                                <Input name="title" id="title" onChange={handleChange} value={values.title} />
                                <ErrorMessage component="label" name="title" className="text-red font-light w-full" />

                                <label htmlFor="content" className="text-roxo_oficial text-lg font-bold mt-4">
                                    Texto
                                </label>
                                <DefaultEditor
                                    value={values.content}
                                    onChange={e => {
                                        setFieldValue("content", e.target.value);
                                    }}
                                />
                                <ErrorMessage component="label" name="content" className="text-red font-light w-full" />
                            </div>
                            <button
                                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                                type="submit"
                                onClick={handleSubmit}
                                disabled={isSubmitting}>
                                {isSubmitting ? (
                                    <>
                                        Salvando <ClipLoader size={10} loading={isSubmitting} />
                                    </>
                                ) : (
                                    <>Salvar</>
                                )}
                            </button>
                        </form>
                    )}
                </Formik>
            </>
        </Modal>
    );
};
export default ModalNew;
